angular.module('llax')
    .controller('AdminSettingsController',
        /*@ngInject*/
        function($dialogs, $rootScope, $scope, $timeout, $translate, growl,
                 HttpStatus, RevalidateItemsResource, RecalculateStatisticsResource, CurrentOperationsResource, ReindexItemsResource, ExportItemResource) {

            var OPERATION_KEYS = {
                'recalculate': 'RECALCULATE_STATISTICS'
            };

            $scope.currentOperations = $scope.currentOperations || [];

            $scope.operationIsInProgress = false;

            $scope.getCurrentOperation = function() {
                CurrentOperationsResource.query({ textsOnly: true },
                    function(response) {
                        $scope.currentOperations = response;
                    },
                    function(response) {
                        $scope.status = response.status;
                    });
            };
            $scope.getCurrentOperation();

            $scope.$watch('currentOperations', function(value) {
                if (value.toString().match(/\w/g) != null) {
                    $scope.operationIsInProgress = true;
                } else {
                    $scope.operationIsInProgress = false;
                }
            });

            $scope.startOperation = function(operationName, force) {

                // $scope.operationIsInProgress = true;

                switch (operationName) {
                    case 'revalidate':
                        RevalidateItemsResource.get();
                        break;
                    case 'recalculate':
                        RecalculateStatisticsResource.get({ force: force },
                        function() {
                            growl.info('ADMINISTRATION.RECALCULATION_IN_PROGRESS');
                        }, function(errorResponse) {
                            if (!force && errorResponse.status === HttpStatus.PRECONDITION_FAILED) {
                                restartOperation(operationName, $translate.instant('ADMINISTRATION.RECALCULATE_STATISTICS'));
                            }
                            return;
                        });
                        break;
                    case 'revalidateOnlyDirty':
                        RevalidateItemsResource.get({onlyDirty:true});
                        break;
                    case 'reindexAllItems':
                        ReindexItemsResource.reindex();
                }

                $timeout(function() {
                    $scope.getCurrentOperation();
                }, 500);

            };

            function restartOperation(operationName, operationLabel) {

                var operationKey = OPERATION_KEYS[operationName];
                CurrentOperationsResource.get({operationKey: operationKey}, function(operation) {

                    if (_.isEmpty(operation) || _.isNil(operation.updatedAt)) {
                        $scope.startOperation(operationName, true);
                        return;
                    }

                    var lastUpdatedAt = $rootScope.formatDateToSimpleString(operation.updatedAt);
                    var msg = $translate.instant('ADMINISTRATION.FORCE_OPERATION_EXECUTION', {
                        operationName: operationLabel,
                        lastUpdatedAt: lastUpdatedAt
                    });

                    $dialogs.confirm('ADMINISTRATION.OPERATION_IN_PROGRESS_HEADER', msg).result.then(function() {
                        $scope.startOperation(operationName, true);
                    });

               });

            }

            $scope.deleteOldUnfinishedExports = function() {
                growl.info('OPERATION.DELETE_OLD_UNFINISHED_EXPORTS.STARTED');
                var presentDate = new Date();
                presentDate.setHours(presentDate.getHours() - 24);
                var reqDateVal = presentDate.toISOString();

                ExportItemResource.delete({
                    before: reqDateVal
                }, function(response) {
                    growl.success('OPERATION.DELETE_OLD_UNFINISHED_EXPORTS.FINISHED');
                });
            };

        }
    );
